<template>
    <v-container class="d-flex justify-space-around align-center">
        <div class="d-block text-center">
            <v-icon size="100" color="primary">mdi-emoticon-cry-outline</v-icon>
            <h1>Page not found</h1>
            <h3>This page doesn't exist! Check the URL and try again.</h3>
        </div>
    </v-container>
</template>

<script>
    export default {
        title: "404 Not Found"
    }
</script>
